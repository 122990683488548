<template>
  <div>
    <b-dropdown
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      split
      :text="selectedLabel"
      right
      variant="primary"
      class="float-right"
    >
        <b-dropdown-item
          v-for="option in options"
          :key="option.value"
          @click="onOptionChange(option.value)"
        >
          {{ option.label }}
        </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOption: localStorage.getItem('dataversion') || 'D-01',
      options: [
        { value: 'D-01', label: 'G25 V1' },
        { value: 'D-02', label: 'DeepA V2' },
      ],
    };
  },
  methods: {
    onOptionChange(item) {
      // Seçilen versiyonu localStorage'e kaydet
      localStorage.setItem('dataversion', item);

      // Sayfayı yenile
      window.location.reload();
    },
  },
  computed: {
    selectedLabel() {
      return this.options.find(x => x.value === this.selectedOption).label;
    },
  },
};
</script>

<style scoped>
/* İsteğe bağlı stil */
</style>
